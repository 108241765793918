import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./blog-post.scss"

const BlogPostTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const post = data.markdownRemark
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className="blog-post-header">
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <small className="blog-post-date">{post.frontmatter.date}</small>
        </header>
        <section
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <footer className="blog-post-footer">
          <small className="blog-post-authors">by {post.frontmatter.authors.map(({ name, fields }) => <Link key={name} to={fields.slug}>{name}</Link>).reduce((previous, current) => Array.isArray(previous) ? [...previous, ", ", current] : [previous, ", ", current])}</small>

          {!!post.frontmatter.tags &&
            <div className="blog-post-tags">
              {post.frontmatter.tags.sort().map(({ name, fields }) => (
                <Link key={name} className="blog-post-tag" to={fields.slug}>{name}</Link>
              ))}
            </div>
          }
        </footer>
      </article>
      <nav className="blog-post-nav">
        <ul>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                ← {next.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                {previous.frontmatter.title} →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        authors {
          name
          fields {
            slug
          }
        }
        tags {
          name
          fields {
            slug
          }
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
